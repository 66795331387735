
.select-wrapper {
    display: flex;
    justify-content: space-between;
}
.assign-project-list {
    display: flex;
    height: 450px;
    max-height: 450px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
}
.class-list {
    height: 100%;
    width: 60%;
    border-right: 1px solid #DDDDDD;
    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;
    }
    .class-tree {
        /*width: 100%;*/
        padding: 15px;
        ::v-deep .el-tree-node__content {
            height: 37px;
        }
    }

    &.course-lists {
        width: 100%;
        ::v-deep .el-scrollbar__wrap {
            height: 100%;

            .el-scrollbar__view {
                height: 100%;
                .course-cascader {
                    height: 100%;
                    .el-cascader-menu__wrap {
                        height: 100%;
                    }
                }
            }
        }
    }
}
.app-form-list {
    border-right: 1px solid transparent;
    .info-form {
        padding: 15px;
        .el-form-item-logo {
            margin-bottom: 0;
        }
        .upload-cover {
            position: relative;
            background-color: #DCECFE;
            border-color: #DCECFE;
            padding: 0;
            width: 80px;
            height: 80px;
            color: #409EFF;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            border-radius: 10px;
            i {
                width: 16px;
                height: 16px;
                margin: 5px;
            }
        }
        .cover-input {
            position: absolute;
            width: 80px;
            height: 80px;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
        .up-img-show {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 80px;
            height: 80px;
            top: 0;
            left: 100px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .app-input {
        width: 330px;
    }
}
.app-list {
    border-right: 1px solid #DDDDDD;
    .project-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:nth-child(1) {
            margin-top: 13px;
            padding-top: 0 !important;
        }
        .project-text {
            display: flex;
            height: 100%;
            width: 100%;
            .project-name {
                display: block;
                width: calc(100% - 40px);
            }
        }
    }
    .current {
        background-color: #f5f7fa;
    }
}
.project-list {
    height: 100%;
    width: 40%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            height: 100%;
        }
    }
    .project-item {
        display: flex;
        height: 37px;
        line-height: 37px;
        padding: 0 15px;
        &:nth-child(1) {
            padding-top: 13px;
        }
        .checkbox-group {
            margin-right: 10px;
            .checkbox-label {
                ::v-deep .el-checkbox__label {
                    display: none;
                }
            }
        }

        .project-name.ellispase-1 {
            display: inline-block;
            white-space: nowrap;         /* 禁止换行 */
            overflow: hidden;            /* 隐藏超出容器的部分 */
            text-overflow: ellipsis;
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
